<template>
    <div class="p-grid crud-demo">
        <div class="p-col-12">
            <div class="card">
                <Toast/>
                <h5>搜索条件</h5>
                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <div class="p-grid p-formgrid">

                            <div class="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
						<span class="p-input-icon-right">
							<InputText type="text" v-model="listFrom.electricCode" placeholder="电箱码"/>
							<i class="pi pi-search"/>
						</span>
                            </div>

                            <div class="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
						<span class="p-input-icon-right">
							<InputText type="text" v-model="listFrom.name" placeholder="业主姓名"/>
							<i class="pi pi-search"/>
						</span>
                            </div>

                            <div class="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
						<span class="p-input-icon-left p-input-icon-right">
						<span class="p-input-icon-right">
							<InputText type="text" v-model="listFrom.phone" placeholder="业主电话"/>
							<i class="pi pi-search"/>
						</span>
						</span>
                            </div>
                        </div>
                        <!--                        <div style="margin-left: 1vw">
                                                    <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name"
                                                              placeholder="全部"/>
                                                </div>-->
                        <div style="margin-left: 1vw">
                            <Button label="搜索" icon="pi pi-search" class="p-button-success p-mr-2" @click="selList()"/>
                        </div>
                    </template>
                </Toolbar>

                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <Button label="新增" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew"/>
                    </template>
                </Toolbar>

                <DataTable :value="products" :lazy="true" ref="dataTable"
                           :paginator="true" :rows="10" :totalRecords="totalRecords" :loading="loading"
                           @page="onPage($event)" :filters="filters" @sort="onPage($event)"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
                           responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5 class="p-m-0">电箱信息列表</h5>
                        </div>
                    </template>
                    <Column field="electricCode" header="电箱码">
                        <template #body="slotProps">
                            <span class="p-column-title">electricCode</span>
                            {{slotProps.data.electricCode}}
                        </template>
                    </Column>
                    <Column field="userName" header="业主姓名">
                        <template #body="slotProps">
                            <span class="p-column-title">userName</span>
                            {{slotProps.data.userName}}
                        </template>
                    </Column>
                    <Column field="userMobile" header="业主电话">
                        <template #body="slotProps">
                            <span class="p-column-title">userMobile</span>
                            {{slotProps.data.userMobile}}
                        </template>
                    </Column>
                    <Column field="category" header="设备状态">
                        <template #body="slotProps">
                            <span class="p-column-title">deviceStatus</span>
                            <span :class="'product-badge status-' + slotProps.data.deviceStatus"
                                  v-if="slotProps.data.deviceStatus === 'ONLINE'">在线</span>
                            <span :class="'product-badge status-' + slotProps.data.deviceStatus"
                                  v-if="slotProps.data.deviceStatus === 'UNACTIVE'">未激活</span>
                            <span :class="'product-badge status-' + slotProps.data.deviceStatus"
                                  v-if="slotProps.data.deviceStatus === 'OFFLINE'">离线</span>
                            <span :class="'product-badge status-' + slotProps.data.deviceStatus"
                                  v-if="slotProps.data.deviceStatus === '' || !slotProps.data.deviceStatus">未激活</span>
                        </template>
                    </Column>
                    <Column field="userHome" header="住址">
                        <template #body="slotProps">
                            <span class="p-column-title">userHome</span>
                            {{slotProps.data.userHome}}
                        </template>
                    </Column>
                    <Column field="create_time" header="创建时间" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">createTime</span>
                            {{e_fmtDate(slotProps.data.createTime)}}
                        </template>
                    </Column>
                    <Column field="uploadUserName" header="上传人">
                        <template #body="slotProps">
                            <span class="p-column-title">uploadUserName</span>
                            {{slotProps.data.uploadUserName}}
                        </template>
                    </Column>
                    <Column header="操作">
                        <template #body="slotProps">
                            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-success p-mr-2"
                                    @click="showProduct(slotProps.data)"/>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2"
                                    @click="editProduct(slotProps.data)"/>
                            <Button icon="pi pi-map" class="p-button-rounded p-button-info p-mr-2"
                                    @click="showMap(slotProps.data)"/>
                            <Button icon="pi pi-align-justify" class="p-button-rounded p-button-secondary p-mr-2"
                                    @click="showSelectBoxLine(slotProps.data)"/>
                            <Button icon="pi pi-info" class="p-button-rounded p-button-warning p-mr-2"
                                    @click="showSelectWarning(slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                                    @click="confirmDeleteProduct(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="productShow" :style="{width: '500px',height: ''}" header="详细信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="electricCode">电箱码</label>
                        <InputText id="electricCode" v-model.trim="product.electricCode" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="userName">业主姓名</label>
                        <InputText id="userName" v-model.trim="product.userName" required="true" autofocus disabled/>
                    </div>
                    <div class="p-field">
                        <label for="userMobile">业主电话</label>
                        <InputText id="userMobile" v-model.trim="product.userMobile" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="userHome">业主地址</label>
                        <InputText id="userHome" v-model.trim="product.userHome" required="true" autofocus disabled/>
                    </div>
                    <div class="p-field">
                        <label for="uploadUserName">上传人</label>
                        <InputText id="uploadUserName" v-model.trim="product.uploadUserName" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="status">状态</label>
                        <InputText id="status" placeholder="启用" v-if="product.status === '0'" required="true" autofocus
                                   disabled/>
                        <InputText id="status" placeholder="禁用" v-if="product.status === '1'" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="remark">备注</label>
                        <Textarea id="remark" v-model="product.remark" required="true" rows="3" cols="20" disabled/>
                    </div>
                    <div class="p-field">
                        <label for="createTime">加入时间</label>
                        <InputText id="createTime" :model-value="e_fmtDate(product.createTime)" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="updateTime">修改时间</label>
                        <InputText id="updateTime" :model-value="e_fmtDate(product.updateTime)" required="true" autofocus
                                   disabled/>
                    </div>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text" @click="hideShow"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="修改信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="electricCode">电箱码</label>
                        <InputText id="electricCode" v-model.trim="product.electricCode" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="description">备注</label>
                        <Textarea id="description" v-model="product.remark" required="true" rows="3" cols="20"/>
                    </div>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="提交" icon="pi pi-check" class="p-button-text" @click="saveProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="productAdd" :style="{width: '450px'}" header="新增电箱信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="elcCode">电箱码</label>
                        <InputText id="elcCode" v-model="product.electricCode" required="true" autofocus/>
                    </div>
                    <div class="p-field">
                        <label for="name">业主姓名</label>
                        <InputText id="name" v-model="product.userName" required="true" autofocus/>
                    </div>
                    <div class="p-field">
                        <label for="phone">业主电话</label>
                        <InputText id="phone" v-model="product.userMobile" required="true" autofocus/>
                    </div>
                    <div class="p-field">
                        <label for="remarkAdd">备注</label>
                        <Textarea id="remarkAdd" v-model="product.remark" required="true" rows="3" cols="20"/>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" class="p-button-text" @click="hideAdd"/>
                        <Button label="提交" icon="pi pi-check" class="p-button-text" @click="addProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="删除" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="product">确定要删除电箱<b>{{product.electricCode}}</b>吗?</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" class="p-button-text"
                                @click="deleteProductDialog = false"/>
                        <Button label="删除" icon="pi pi-check" class="p-button-text" @click="deleteProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="product">Are you sure you want to delete the selected products?</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" class="p-button-text"
                                @click="deleteProductsDialog = false"/>
                        <Button label="删除" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="selectBoxLineDialog" :style="{width: '1000px'}" header="电箱日志" :modal="true">
                    <DataTable :value="boxLineList" :lazy="true"
                               :paginator="true" :rows="10" :totalRecords="boxLineTotalRecords"
                               :loading="boxLineloading" @page="onLineBoxPage($event)"
                               responsiveLayout="scroll">

                        <Column field="userName" header="线路">
                            <template #body="slotProps">
                                <span class="p-column-title">lineCode</span>
                                {{lineNames[slotProps.data.lineCode]||slotProps.data.lineCode}}
                            </template>
                        </Column>
                        <Column field="userMobile" header="状态">
                            <template #body="slotProps">
                                <span class="p-column-title">lineStatus</span>
                                {{faultNames[slotProps.data.lineStatus]}}
                            </template>
                        </Column>
                        <Column field="createTime" header="时间">
                            <template #body="slotProps">
                                <span class="p-column-title">rtcTime</span>
                                {{e_fmtDate(slotProps.data.rtcTime)}}
                            </template>
                        </Column>
                    </DataTable>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text"
                                @click="selectBoxLineDialog = false"/>
                    </template>
                </Dialog>
                <Dialog v-model:visible="selectWarningDialog" :style="{width: '1000px'}" header="电箱消息" :modal="true">
                    <DataTable :value="warningList" :lazy="true"
                               :paginator="true" :rows="10" :totalRecords="warningTotalRecords"
                               :loading="warningloading" @page="onWarningPage($event)"
                               responsiveLayout="scroll">

                        <Column field="userName" header="标题">
                            <template #body="slotProps">
                                <span class="p-column-title">title</span>
                                {{slotProps.data.title}}
                            </template>
                        </Column>
                        <Column field="userName" header="内容">
                            <template #body="slotProps">
                                <span class="p-column-title">content</span>
                                {{slotProps.data.content}}
                            </template>
                        </Column>
                        <Column field="createTime" header="时间">
                            <template #body="slotProps">
                                <span class="p-column-title">updateTime</span>
                                {{e_fmtDate(slotProps.data.updateTime)}}
                            </template>
                        </Column>
                    </DataTable>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text"
                                @click="selectWarningDialog = false"/>
                    </template>
                </Dialog>

                <Dialog :visible="true" :style="{width: '1000px', display: setMapDialog?'flex':'none'}" header="电箱安装位置"
                        :modal="setMapDialog" :closable="false">
                    <div class="p-field">
                        <label for="shopcoord" style="margin-left: 4vw">当前坐标:</label>
                        <InputText id="shopcoord" name="lng_lat" style="margin-left: 1vw;width: 400px"
                                   v-model.trim="lnglat" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="suggestId" style="margin-left: 4vw">安装地址:</label>
                        <InputText id="suggestId" name="dizhi" style="margin-left: 1vw;width: 400px"
                                   required="true" autofocus :value="userHome"
                        />
                    </div>
                    <div id="searchResultPanel"
                         style="border:1px solid #C0C0C0;width:150px;height:auto; display:none;"></div>
                    <div style="width: 900px; height: 500px;border:#ccc solid 1px;font-size:12px" id="allmap"></div>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text"
                                @click="setMapDialog = false"/>
                        <Button label="提交" icon="pi pi-check" class="p-button-text" @click="setMapLngLat"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>
<script>
    import ProductService from '../service/ProductService';
    import axios from "axios";
    import { getCurrentInstance } from '@vue/runtime-core';

    const BMap = window.BMap;
    const faultNames = {
        0: '初始状态',
        8: '分闸',
        9: '合闸',
        10: '手动分闸',
        11: '手动合闸',
        14: '电箱断网',
        15: '电箱上线',
        2: '高温预警60℃',
        12: '火灾警报68℃',
        16: '火灾断电90℃',
        13: '超过自定温度',
        3: '过流预警',
        6: '过压预警',
        17: '欠压预警',
        18: '过流警报',
        19: '过压警报',
        20: '欠压警报',
    };
    const warningType = {
        1: '后台广播',
        2: '公司广播',
        3: '大屏消息',
        4: 'app消息'
    };
    export default {
        data() {
            return {
                totalRecords: 0,
                loading: false,
                faultNames,
                warningType,
                products: null,
                boxLineloading: false,
                boxLineList: null,
                boxLineTotalRecords: 0,
                warningloading: false,
                warningList: null,
                warningTotalRecords: 0,
                productShow: false,
                productDialog: false,
                productAdd: false,
                setMapDialog: false,
                lnglat: "",
                myValue: null,
                map: null,
                userHome: null,
                deleteProductDialog: false,
                deleteProductsDialog: false,
                selectBoxLineDialog: false,
                selectWarningDialog: false,
                product: {},
                selectedProducts: null,
                filters: {},
                page: 0,
                boxLinePage: 0,
                submitted: false,
                eBoxCode: "",
                listFrom: {
                    electricCode: "",
                    name: "",
                    phone: "",
                },
                statuses: [
                    {label: '启用', value: '0'},
                    {label: '禁用', value: '1'}
                ],
                dropdownValue: null,
                lineNames: {L0: '总线'},
                dropdownValues: [
                    {name: '全部', code: 'LDN'},
                    {name: '已设置地址', code: 'NY'},
                    {name: '未设置地址', code: 'RM'}
                ]
            }
        },
        productService: null,
        created() {
            this.productService = new ProductService();
        },
        mounted() {
            /*    axios.get('assets/layout/data/products.json').then(data => this.products = data.data.data);*/
            /*
                        axios.post("/houseKeeperCompany/user/getCompanyBackStageByPage").then(data => this.products = data.data.data);
            */
            this.feath();
            const map = new BMap.Map("allmap");
            map.centerAndZoom("北京", 12);
            map.enableScrollWheelZoom(true);
            //建立一个自动完成的对象
            const ac = new BMap.Autocomplete({
                "input": this.G("suggestId")
            });
            console.log(ac);
            ac.addEventListener("onhighlight", e => {  //鼠标放在下拉列表上的事件
                var str = "";
                var _value = e.fromitem.value;
                var value = "";
                if (e.fromitem.index > -1) {
                    value = _value.province + _value.city + _value.district + _value.street + _value.business;
                }
                str = "FromItem<br />index = " + e.fromitem.index + "<br />value = " + value;

                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value = _value.province + _value.city + _value.district + _value.street + _value.business;
                }
                str += "<br />ToItem<br />index = " + e.toitem.index + "<br />value = " + value;
                this.G("searchResultPanel").innerHTML = str;
            });
            ac.addEventListener("onconfirm", e => {    //鼠标点击下拉列表后的事件
                var _value = e.item.value;
                this.myValue = _value.province + _value.city + _value.district + _value.street + _value.business;
                this.G("searchResultPanel").innerHTML = "onconfirm<br />index = " + e.item.index + "<br />myValue = " + this.myValue;
                this.setPlace();
            });
            map.addEventListener("click", e => {
                const geocoder = new BMap.Geocoder();
                const point = new BMap.Point(e.point.lng, e.point.lat);
                geocoder.getLocation(point, geocoderResult => {
                    this.userHome = geocoderResult.address;
                    //prompt("鼠标单击地方的经纬度为：",e.point.lng + "," + e.point.lat);
                    this.lnglat = e.point.lng + "," + e.point.lat;
                });

            });
            this.map = map;
        },
        methods: {
            showMap(product) {
                this.setMapDialog = true;
                this.product = product.id;
                this.map.clearOverlays();
                this.lnglat = product.lng + "," + product.lat;
                this.userHome = product.userHome;
                const lnt_lag = this.lnglat;
                let lng_val;
                let lat_val;
                if (lnt_lag.indexOf("null") === -1) {
                    lng_val = lnt_lag.split(',')[0];
                    lat_val = lnt_lag.split(',')[1];
                } else {
                    lng_val = "116.41566";
                    lat_val = "39.940562";
                }
                this.lnglat = lng_val + "," + lat_val;
                // 百度地图API功能
                const point = new BMap.Point(lng_val, lat_val);
                this.map.centerAndZoom(point, 12);
                setTimeout(() => this.map.panTo(point), 300);
                // 将标注添加到地图中
                this.map.addOverlay(new BMap.Marker(point));
            },
            setPlace() {
                this.map.clearOverlays();    //清除地图上所有覆盖物
                const local = new BMap.LocalSearch(this.map, { //智能搜索
                    onSearchComplete: () => {
                        const pp = local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                        this.map.centerAndZoom(pp, 18);
                        this.map.addOverlay(new BMap.Marker(pp));    //添加标注
                        this.lnglat = pp.lng + ',' + pp.lat;
                        this.userHome = this.myValue
                    }
                });
                local.search(this.myValue);
            },
            G(id) {
                return document.getElementById(id);
            },
            setMapLngLat() {
                const id = this.product;
                const lnglat = this.lnglat;
                const dizhi = this.userHome;
                if (!lnglat) return this.err("请选择地址");
                if (!dizhi) return this.err("请输入地址");
                axios
                    .post(
                        "/houseKeeperCompany/user/setLocation",
                        `id=${id}&lnglat=${lnglat}&dizhi=${dizhi}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.setMapDialog = false;
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.product = {}
                            this.$toast.add({severity: 'success', summary: data.msg, detail: data.data, life: 3000});
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });

            },
            feath() {
                this.loading = true;
                const page = 0;
                axios
                    .post(
                        "/houseKeeperCompany/user/getCompanyBackStageByPage",
                        `page=${page}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            console.log(data);
                            this.products = data.data.content;
                            this.totalRecords = data.data.totalElements
                            this.loading = false;
                        } else{
                            if(data.msg === '当前账号在其他地点登录，请重新登录' && data.code === -3){
                                this.$router.push('/login');
                            }
                            this.err(data.msg, "查询失败", 5000);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            boxLinefeath(eBoxCode) {
                this.eBoxCode = eBoxCode;
                this.boxLineloading = true;
                const page = 0;
                const size = 10;
                axios
                    .post(
                        "/houseKeeperFault/user/getCompanyLineBox",
                        `page=${page}&size=${size}&eBoxCode=${eBoxCode}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            console.log(data);
                            this.boxLineList = data.data.list;
                            this.boxLineTotalRecords = data.data.count;
                            axios
                                .post(
                                    "/houseKeeperEBoxLine/user/getCompanyLineNames",
                                    `electricCode=${eBoxCode}`
                                )
                                .then(({data}) => {
                                    if (data.code === 0) {
                                        console.log(data);
                                        for (const item of data.data) {
                                            if (item.lineCode !== item.lineName) {
                                                this.lineNames[item.lineCode] = item.lineName
                                            }
                                        }
                                    } else this.err(data.msg, "查询失败", 5000);
                                })
                                .catch((err) => {
                                    console.error(err);
                                    this.err("系统错误，请稍候重试");
                                });
                            this.boxLineloading = false;
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },

            err(msg, title, life) {
                this.$toast.add({
                    severity: "error",
                    summary: title,
                    detail: msg,
                    life: life || 3000,
                });
            },
            onPage(event) {
                this.loading = true;
                const page = event.page;
                const {electricCode, name, phone} = this.listFrom;

                this.page = event.page;
                let sort = '';
                if(event.sortField){
                    sort = event.sortField+','+ (event.sortOrder===1?'asc':'desc');
                }
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperCompany/user/getCompanyBackStageByPage",
                            `page=${page}&size=${event.rows}&sort=${sort}&electricCode=${electricCode}&name=${name}&phone=${phone}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                console.log(data);
                                this.products = data.data.content;
                                this.loading = false;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                }, 500);
            },
            onLineBoxPage(event) {
                this.boxLineloading = true;
                const page = event.page;
                const size = 10;
                const eBoxCode = this.eBoxCode;
                this.page = event.page;
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperFault/user/getCompanyLineBox",
                            `page=${page}&size=${size}&eBoxCode=${eBoxCode}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                this.boxLineList = data.data.list;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                    this.boxLineloading = false;
                }, 500);
            },
            onWarningPage(event) {
                this.warningloading = true;
                const page = event.page;
                const size = 10;
                const eBoxCode = this.eBoxCode;
                this.page = event.page;
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperFault/user/getCompanyNotificationPage",
                            `page=${page}&size=${size}&eBoxCode=${eBoxCode}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                this.warningList = data.data.list;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                    this.warningloading = false;
                }, 500);
            },
            selList() {
                this.loading = true;
                const {electricCode, name, phone} = this.listFrom;
                const page = this.page;
                this.$refs.dataTable.resetPage();
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperCompany/user/getCompanyBackStageByPage",
                            `page=${page}&electricCode=${electricCode}&name=${name}&phone=${phone}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                console.log(data);
                                this.products = data.data.content;
                                this.totalRecords = data.data.totalElements;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                    this.loading = false;
                }, 500);
            },
            formatCurrency(value) {
                if (value)
                    return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                return;
            },
            e_fmtDate(date, fmt) {
                const { proxy } = getCurrentInstance();
                return proxy.$fmtDate(date, fmt);
            },
            openNew() {
                this.product = {};
                this.submitted = false;
                this.productAdd = true;
            },
            hideShow() {
                this.productShow = false;
                this.submitted = false;
            },
            hideDialog() {
                this.productDialog = false;
                this.submitted = false;
            },
            hideAdd() {
                this.productAdd = false;
                this.submitted = false;
            },
            saveProduct() {
                console.log(this.product)
                const {id, remark} = this.product;
                this.submitted = true;
                axios
                    .post(
                        "/houseKeeperCompany/user/setCompanyBackStageById",
                        `id=${id}&remark=${remark}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.productDialog = false;
                            this.product = {};
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({severity: 'success', summary: data.msg, detail: data.data, life: 3000});
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });

            },
            addProduct() {
                console.log(this.product)
                const {electricCode, remark, userMobile, userName} = this.product;
                if (!electricCode) return this.err("请输入电箱码")
                else if (!/^[a-zA-Z0-9]+$/.test(electricCode))
                    return this.err("电箱码不正确");
                if (!userName) return this.err("请输入业主姓名")
                else if (!/^[a-zA-Z\u4e00-\u9fa5]+$/g.test(userName))
                    return this.err("业主姓名不正确");
                if (!userMobile) return this.err("请输入业主电话")
                else if (!/^1[345789]\d{9}$/.test(userMobile))
                    return this.err("业主电话不正确");
                axios
                    .post(
                        "/houseKeeperCompany/user/addCompanyBackStage",
                        `electricCode=${electricCode}&remark=${remark}&userName=${userName}&userMobile=${userMobile}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.productAdd = false;
                            this.product = {};
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({severity: 'success', summary: data.msg, detail: data.data, life: 3000});
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            showProduct(product) {
                this.product = {...product};
                this.productShow = true;
            },
            showSelectBoxLine(product) {
                this.product = {...product};
                this.boxLinefeath(product.electricCode);
                this.selectBoxLineDialog = true;
            },
            showSelectWarning(product) {
                const eBoxCode = product.electricCode;
                this.eBoxCode = eBoxCode;
                this.warningloading = true;
                const page = 0;
                const size = 10;
                axios
                    .post(
                        "/houseKeeperFault/user/getCompanyNotificationPage",
                        `page=${page}&size=${size}&eBoxCode=${eBoxCode}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.warningList = data.data.list;
                            this.warningTotalRecords = data.data.count;
                            this.warningloading = false;
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
                this.selectWarningDialog = true;
            },
            editProduct(product) {
                this.product = {...product};
                if (this.product.status === '0') {
                    this.product.status = {label: "启用", value: "0"};
                } else {
                    this.product.status = {label: "禁用", value: "1"};
                }
                this.productDialog = true;
            },
            confirmDeleteProduct(product) {
                this.product = product;
                this.deleteProductDialog = true;
            },
            deleteProduct() {
                const electricCode = this.product.electricCode
                axios
                    .post(
                        "/houseKeeperCompany/user/delBoxUserAdmin",
                        `electricCode=${electricCode}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.product = {};
                            this.deleteProductDialog = false;
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({
                                severity: 'success',
                                summary: '删除成功！',
                                detail: '删除电箱码' + this.product.electricCode + '成功！',
                                life: 3000
                            });
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            findIndexById(id) {
                let index = -1;
                for (let i = 0; i < this.products.length; i++) {
                    if (this.products[i].id === id) {
                        index = i;
                        break;
                    }
                }
                return index;
            },
            createId() {
                let id = '';
                var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                for (var i = 0; i < 5; i++) {
                    id += chars.charAt(Math.floor(Math.random() * chars.length));
                }
                return id;
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            confirmDeleteSelected() {
                this.deleteProductsDialog = true;
            },
            deleteSelectedProducts() {
                this.products = this.products.filter(val => !this.selectedProducts.includes(val));
                this.deleteProductsDialog = false;
                this.selectedProducts = null;
                this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
            }
        }
    }
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .map {
        width: 100%;
        height: 300px;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-instock {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-outofstock {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-lowstock {
            background: #FEEDAF;
            color: #8A5340;
        }

        &.status-UNACTIVE {
            background: #ffffff;
            color: #f50500;
        }

        &.status-ONLINE {
            background: #ffffff;
            color: #01ff1f;
        }

        &.status-OFFLINE {
            background: #ffffff;
            color: #b7b7b7;
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: .25rem;
        }
    }
</style>
